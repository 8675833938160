import React from 'react'
import { DatePicker, Space } from 'antd';
const IssueDate = (props) => {
    return (
        <div>
            <Space direction="vertical" style={{ color: "red" }}>
                <DatePicker style={{
            width: "320px",
            borderRadius: "0px",
            cursor: "pointer",
            padding: "0px 10px",
            borderRadius:"3px",
            border:"1px solid #8B8FAE",
          }}
 format="MM/DD/YYYY" value={props.value} disabledDate={d=>d.isAfter(props.expireDate)} onChange={props.onChangeDate} />
            </Space>
        </div>
    )
}
export default IssueDate;